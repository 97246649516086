// Calculate real pointer position on canvas taking scale and stage position into account

import { mergeParticipantAttr } from '$lib/components/component-functions-twilio';
import type { AnnotationContext } from '$lib/components/models';
import type { Participant } from '@twilio/conversations';
import type Konva from 'konva';

// This is required if you scale or reposition your stage x/y coordinates
export function getRealPointerPos(pos: Konva.Vector2d, stage: Konva.Stage) {
	const realPos = {
		x: 0,
		y: 0
	};

	const stageScale = stage.scaleX(); // Only care about x scale as y is always the same

	realPos.x = pos.x / stageScale - stage.x() / stageScale;
	realPos.y = pos.y / stageScale - stage.y() / stageScale;

	return realPos;
}

type Size = {
	width: number;
	height: number;
};

export const resizeToFitBounds = (elementSize: Size, bounds: Size): Size => {
	const newSize = { width: elementSize.width * 5, height: elementSize.height * 5 };

	const elementRatio = elementSize.width / elementSize.height;
	const boundsRatio = bounds.width / bounds.height;

	if (elementRatio >= boundsRatio) {
		// If width is bigger than height
		newSize.width = bounds.width;
		newSize.height = (bounds.width / elementSize.width) * elementSize.height;
	} else {
		// If height is bigger than width
		newSize.width = (bounds.height / newSize.height) * newSize.width;
		newSize.height = bounds.height;
	}

	return newSize;
};

// From here -> https://stackoverflow.com/a/42196770
function loadImage(imageUrl: string, onprogress: (ratio: number) => void) {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		let notifiedNotComputable = false;

		xhr.open('GET', imageUrl, true);
		xhr.responseType = 'arraybuffer';

		xhr.onprogress = function (ev) {
			if (ev.lengthComputable) {
				onprogress(parseInt((ev.loaded / ev.total) * 100));
			} else {
				if (!notifiedNotComputable) {
					notifiedNotComputable = true;
					onprogress(-1);
				}
			}
		};

		xhr.onloadend = function () {
			if (!xhr.status.toString().match(/^2/)) {
				reject(xhr);
			} else {
				if (!notifiedNotComputable) {
					onprogress(100);
				}

				const options = {} as any;
				const headers = xhr.getAllResponseHeaders();
				const m = headers.match(/^Content-Type\:\s*(.*?)$/im);

				if (m && m[1]) {
					options.type = m[1];
				}

				const blob = new Blob([this.response], options);

				resolve(window.URL.createObjectURL(blob));
			}
		};

		xhr.send();
	});
}

export const loadKonvaImage = async (
	url: string,
	onprogress: (ratio: number) => void,
	crossOrigin = 'Anonymous'
): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		const img = document.createElement('img');

		loadImage(url, onprogress).then(
			(imgSrc) => {
				// Image loaded, we set the url as source of our image
				img.src = imgSrc as any;
			},
			(e) => {
				// An error occured. We have the XHR object to see what happened.
				console.error("Konva loadImage() error, couldn't load the image", e);
				reject(e);
			}
		);

		function onload() {
			// Once the image is loaded from the imgSrc value, we have the right width and height and we can resolve the promise
			resolve(img);
		}

		function onerror(e: any) {
			console.error("Konva error, couldn't load the image", e);
			reject(e);
		}
		//img.addEventListener('progress', onprogress); // THIS DOESN'T WORK, so we use loadImage
		img.addEventListener('load', onload);
		img.addEventListener('error', onerror);
		crossOrigin && (img.crossOrigin = crossOrigin);
	});
};

export const closePortalAnnotation = (
	portalAnnotation: AnnotationContext | undefined,
	portalMyParticipant: Participant | undefined
) => {
	if (!portalAnnotation?.canClose) return;

	// If I close my own annotation, I need to remove the current annotation attribute
	if (portalAnnotation?.participantIdentity === portalMyParticipant?.identity) {
		mergeParticipantAttr(portalMyParticipant, {
			currentAnnotation: undefined
		});
	}
};
