import type { Conversation, JSONValue, Message, Participant } from '@twilio/conversations';
import type { PortalUser } from 'fixee-server';
import type {
	BrowserPermissions,
	ParticipantAttributes
} from 'fixee-server/lib/services/portal/portal.shared';
import {
	LocalVideoTrack,
	Participant as VideoParticipant,
	createLocalTracks,
	type LocalTrack,
	type RemoteTrack
} from 'twilio-video';
import { iOS } from '../../utils';
import { resizeToFitBounds } from './mols/annotation/annotation-utils';

// We have separate component function only for stuff relating twilio in order to avoid loading twilio dependencies in other components that don't need it
// It doesn't work too well for now, for example if we load /login and check the network tab in chrome inspector, we can see that this file is loaded

// trackIdToAvoid can be the screenTrackId, we don't wan't to use that to check if the video is enabled
export const isVideoEnabled = (participant?: VideoParticipant, trackIdToAvoid?: string) =>
	Boolean(
		participant &&
			Array.from(participant.videoTracks.values()).find((publication) => {
				if (
					trackIdToAvoid &&
					(publication.track as LocalVideoTrack | undefined)?.id === trackIdToAvoid
				)
					return false;

				return publication.track?.isEnabled;
			})
	);

export const isAudioEnabled = (participant?: VideoParticipant) =>
	Boolean(
		participant &&
			Array.from(participant.audioTracks.values()).find(
				(publication) => publication.track?.isEnabled
			)
	);

export function getAudioSettings(deviceId: string | undefined) {
	return {
		noiseCancellationOptions: iOS()
			? undefined
			: {
					sdkAssetsPath: '/krisp/plugin/1.0.0/dist',
					vendor: 'krisp'
			  },
		echoCancellation: true,
		deviceId: { ideal: deviceId }
	};
}

export function getVideoSettings(
	deviceId: string | undefined,
	facingMode?: 'environment' | 'user'
) {
	return {
		facingMode: facingMode || 'environment',
		deviceId: { ideal: deviceId },
		width: {
			min: 360,
			max: 720,
			ideal: 480
		}
	};
}

export const stopTracks = (tracks: (LocalTrack | MediaStreamTrack)[]) => {
	for (const t of tracks) {
		const track = t as any;
		if (track.stop) track.stop();
	}
};

export async function getRearCameraDeviceId() {
	try {
		const devices = await navigator.mediaDevices.enumerateDevices();
		const videoInputDevices = devices.filter((device) => device.kind === 'videoinput');

		// This doesn't work on iOS for example if the language is not english
		const rearCameraDevice = videoInputDevices.find(
			(device) =>
				device.label.toLowerCase().includes('back') ||
				device.label.toLowerCase().includes('rear')
		);

		return rearCameraDevice ? rearCameraDevice.deviceId : undefined;
	} catch (error) {
		console.error('Error getting rear camera device ID:', error);
		return undefined;
	}
}

export async function initLiveTracks(
	videoDeviceId: string | undefined | null,
	audioDeviceId: string | undefined | null
) {
	return await createLocalTracks({
		audio: audioDeviceId === null ? false : getAudioSettings(audioDeviceId),
		video: videoDeviceId === null ? false : getVideoSettings(videoDeviceId, 'environment')
	});
}

export const getParticipant = (portalUser: PortalUser, participants: Participant[]) =>
	participants.find((ppt) => ppt.identity === (portalUser.teamUserId || portalUser.contactId));

export const getParticipantAttributes = (portalUser: PortalUser, participants: Participant[]) => {
	const attributes = getParticipant(portalUser, participants)?.attributes as
		| ParticipantAttributes
		| undefined;

	return attributes;
};

export const mergeParticipantAttr = async (
	participant: Participant | undefined,
	attributes: Partial<ParticipantAttributes>
) => {
	if (!participant) return;
	const myParticipantAttributes = participant.attributes as ParticipantAttributes;

	// INFO : The max length of attributes is 4096
	await participant.updateAttributes({ ...myParticipantAttributes, ...attributes } as JSONValue);
};

export const handleLivePermissionError = async (
	err: unknown,
	videoInput?: string | undefined,
	audioInput?: string | undefined
) => {
	const error = err as DOMException;
	const permissions: Pick<BrowserPermissions, 'camera' | 'microphone'> = {
		camera: 'denied',
		microphone: 'denied'
	};
	const tracks: LocalTrack[] = [];

	if (error.name === 'NotAllowedError') {
		console.error('Camera and microphone access denied by the user.');

		// We check individually if we can get the camera and microphone. Maybe the user has only denied one of them.
		// Maybe the company has disabled the camera on the device for example.
		try {
			const trcks = await initLiveTracks(null, audioInput);
			tracks.push(...trcks);
			permissions.microphone = 'granted';
		} catch (error) {
			// We don't do anything here because we already have the error
		}

		try {
			const trcks = await initLiveTracks(videoInput, null);
			tracks.push(...trcks);
			permissions.camera = 'granted';
		} catch (error) {
			// We don't do anything here because we already have the error
		}
	} else {
		console.error('An error occurred while requesting camera and microphone access:', error);
	}
	return {
		permissions,
		tracks
	};
};

export const getAllMessagesInConversation = async (portalConversation: Conversation) => {
	let messages: Message[] = [];
	let loadedMessages = await portalConversation.getMessages(200, 0, 'forward');
	messages = [...messages, ...loadedMessages.items];

	while (loadedMessages.hasNextPage) {
		loadedMessages = await loadedMessages.nextPage();
		messages = [...messages, ...loadedMessages.items];
	}

	return messages;
};

export const updateVideoHolderOnTrackDimensionsChanged = (
	track: LocalTrack | RemoteTrack,
	parentDivId: string,
	divElement: HTMLDivElement | undefined
) => {
	if (track.kind === 'video') {
		const parentDiv = document.getElementById(parentDivId);

		if (parentDiv && divElement && track.dimensions.width && track.dimensions.height) {
			const size = resizeToFitBounds(
				{
					width: track.dimensions.width * 100,
					height: track.dimensions.height * 100
				},
				{
					width: parentDiv.clientWidth,
					height: parentDiv.clientHeight
				}
			);
			// console.log('video', {
			// 	width: track.dimensions.width * 100,
			// 	height: track.dimensions.height * 100
			// });
			// console.log('holder', {
			// 	width: parentDiv.clientWidth,
			// 	height: parentDiv.clientHeight
			// });
			// console.log('size', size);

			if (size.height !== 0 && size.width !== 0) {
				divElement.style.maxHeight = `${size.height}px`;
				divElement.style.maxWidth = `${size.width}px`;
			}
		}
	}
};
