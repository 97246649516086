<script lang="ts">
	import { browser } from '$app/environment';
	import { getChromeVersion, isSafari } from '../../../utils';
	import { resizeToFitBounds } from '../mols/annotation/annotation-utils';

	export let size: number = 6;
	export let maxHeightPx: number | undefined = undefined;
	export let icon: any = undefined;
	let div: HTMLDivElement;

	// We have to write the sizes for tailwind to understand
	// h-1 h-2 h-3 h-4 h-5 h-6 h-7 h-8 h-10 h-12 h-16 h-20 h-24 h-32 h-48 h-64
	// w-1 w-2 w-3 w-4 w-5 w-6 w-7 w-8 w-10 w-12 w-16 w-20 w-24 w-32 w-48 w-64

	$: if (browser) {
		const chromeVersion = getChromeVersion(); // On older chrome we have to set a width and height for the icon
		const shouldSetWidth = (chromeVersion && chromeVersion < 88) || isSafari();
		// On safari, we must add a width and height to the svg
		if (shouldSetWidth && div) {
			const svg = div.firstElementChild as SVGSVGElement;
			if (svg) {
				// We calculate the height or the width based on the viewbox
				const viewbox = svg.getAttribute('viewBox');
				if (viewbox) {
					const [x, y, width, height] = viewbox.split(' ');
					let newWidth, newHeight;
					let newSize;

					if (width <= height) {
						const ratio = parseInt(height) / parseInt(width);
						newWidth = div.clientWidth;
						newHeight = div.clientWidth * ratio;

						// For some exceptions, we need to resize the svg to fit the bounds and set a max height, for vertical-ellipsis for example
						if (maxHeightPx && newHeight > maxHeightPx) {
							newSize = resizeToFitBounds(
								{ width: newWidth, height: newHeight },
								{
									height: maxHeightPx,
									width: maxHeightPx / ratio
								}
							);
						}
					} else {
						const ratio = parseInt(width) / parseInt(height);
						newWidth = div.clientHeight * ratio;
						newHeight = div.clientHeight;
					}

					if (newSize) {
						newWidth = newSize.width;
						newHeight = newSize.height;
					}

					svg.style.width = `${newWidth}px`;
					svg.style.height = `${newHeight}px`;
				}
			}
		}
	}
</script>

<svelte:element
	this="div"
	bind:this={div}
	class="w-{size} h-{size} flex justify-center items-center {$$restProps.class ?? ''}"
>
	{#if icon}
		<svelte:component this={icon} />
	{:else}
		<slot />
	{/if}
</svelte:element>
